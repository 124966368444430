import { Variant, Size, variants, sizes } from './variant';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { IconButton } from '@vapordex/uikit';
import { useMemo } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import { Check, ChevronDown } from 'react-feather';
import { DefaultTheme, useTheme } from 'styled-components';
import { RowBetween } from '@components/Layout/Row';

interface ButtonProps extends Omit<MuiButtonProps, 'size' | 'variant'> {
  variant?: Variant;
  size?: Size;
  width?: string;
}

export const Button = ({
  children,
  size,
  sx,
  variant,
  ...props
}: ButtonProps) => {
  const _variant = useMemo(
    () => variants?.[variant] ?? variants.primary,
    [variant],
  );
  const _size = useMemo(() => sizes?.[size] ?? sizes.md, [size]);
  const _sx = useMemo(
    () => ({
      ..._variant,
      ..._size,
      width: props?.width,
      ...sx,
    }),
    [_variant, _size, props?.width, sx],
  );

  return (
    <MuiButton {...props} sx={_sx}>
      {children}
    </MuiButton>
  );
};

export default Button;

export const SwitchIconButton = styled(IconButton)`
  box-shadow: unset;
  padding: 4px;
  background-color: transparent;
  border: 2px solid #d6dada;
  height: fit-content;
  width: fit-content;
  transition: unset;

  &:hover {
    height: fit-content;
    width: fit-content;
    transition: unset;
  }
`;

const ButtonOverlay = styled.div`
  background-color: transparent;
  bottom: 0;
  border-radius: inherit;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 150ms ease background-color;
  width: 100%;
`;

type BaseButtonProps = {
  padding?: string;
  width?: string;
  $borderRadius?: string;
  altDisabledStyle?: boolean;
} & ButtonProps;

export const BaseButton = styled(Button)<BaseButtonProps>`
  padding: ${({ padding }) => padding ?? '16px'};
  width: ${({ width }) => width ?? '100%'};
  font-weight: 500;
  text-align: center;
  border-radius: ${({ $borderRadius }) => $borderRadius ?? '20px'};
  outline: none;
  border: 1px solid transparent;
  color: ${({ theme }) => theme.v2.textPrimary};
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:disabled {
    opacity: 50%;
    cursor: auto;
    pointer-events: none;
  }

  will-change: transform;
  transition: transform 450ms ease;
  transform: perspective(1px) translateZ(0);

  > * {
    user-select: none;
  }

  > a {
    text-decoration: none;
  }
`;

export const ButtonPrimary = styled(BaseButton)`
  background-color: ${({ theme }) => theme.v2.accentAction};
  font-size: 20px;
  font-weight: 600;
  padding: 16px;
  color: ${({ theme }) => theme.v2.accentTextLightPrimary};
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.v2.accentAction)};
    background-color: ${({ theme }) => darken(0.05, theme.v2.accentAction)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.v2.accentAction)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.v2.accentAction)};
    background-color: ${({ theme }) => darken(0.1, theme.v2.accentAction)};
  }
  &:disabled {
    background-color: ${({ altDisabledStyle, disabled, theme }) =>
      altDisabledStyle
        ? disabled
          ? theme.v2.accentAction
          : theme.v2.backgroundInteractive
        : theme.v2.backgroundInteractive};
    color: ${({ altDisabledStyle, disabled, theme }) =>
      altDisabledStyle
        ? disabled
          ? theme.v2.white
          : theme.v2.textSecondary
        : theme.v2.textSecondary};
    cursor: auto;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
  }
`;

export const SmallButtonPrimary = styled(ButtonPrimary)`
  width: auto;
  font-size: 16px;
  padding: ${({ padding }) => padding ?? '8px 12px'};

  border-radius: 12px;
`;

const BaseButtonLight = styled(BaseButton)`
  background-color: ${({ theme }) => theme.v2.accentActionSoft};
  color: ${({ theme }) => theme.v2.accentAction};
  font-size: 20px;
  font-weight: 600;

  &:focus {
    box-shadow: 0 0 0 1pt
      ${({ disabled, theme }) => !disabled && theme.v2.accentActionSoft};
    background-color: ${({ disabled, theme }) =>
      !disabled && theme.v2.accentActionSoft};
  }
  &:hover {
    background-color: ${({ disabled, theme }) =>
      !disabled && theme.v2.accentActionSoft};
  }
  &:active {
    box-shadow: 0 0 0 1pt
      ${({ disabled, theme }) => !disabled && theme.v2.accentActionSoft};
    background-color: ${({ disabled, theme }) =>
      !disabled && theme.v2.accentActionSoft};
  }

  :hover {
    ${ButtonOverlay} {
      background-color: ${({ theme }) => theme.v2.stateOverlayHover};
    }
  }

  :active {
    ${ButtonOverlay} {
      background-color: ${({ theme }) => theme.v2.stateOverlayPressed};
    }
  }

  :disabled {
    opacity: 0.4;
    :hover {
      cursor: auto;
      background-color: transparent;
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
    }
  }
`;

export const ButtonGray = styled(BaseButton)`
  background-color: ${({ theme }) => theme.v2.deprecated_bg1};
  color: ${({ theme }) => theme.v2.textSecondary};
  font-size: 16px;
  font-weight: 500;

  &:hover {
    background-color: ${({ disabled, theme }) =>
      !disabled && darken(0.05, theme.v2.backgroundInteractive)};
  }
  &:active {
    background-color: ${({ disabled, theme }) =>
      !disabled && darken(0.1, theme.v2.backgroundInteractive)};
  }
`;

export const ButtonSecondary = styled(BaseButton)`
  border: 1px solid ${({ theme }) => theme.v2.deprecated_primary4};
  color: ${({ theme }) => theme.v2.accentAction};
  background-color: transparent;
  font-size: 16px;
  border-radius: 12px;
  padding: ${({ padding }) => padding ?? '10px'};

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.v2.deprecated_primary4};
    border: 1px solid ${({ theme }) => theme.v2.deprecated_primary3};
  }
  &:hover {
    border: 1px solid ${({ theme }) => theme.v2.deprecated_primary3};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.v2.deprecated_primary4};
    border: 1px solid ${({ theme }) => theme.v2.deprecated_primary3};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  a:hover {
    text-decoration: none;
  }
`;

export const ButtonOutlined = styled(BaseButton)`
  border: 1px solid ${({ theme }) => theme.v2.backgroundOutline};
  background-color: transparent;
  color: ${({ theme }) => theme.v2.textPrimary};
  &:focus {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.v2.deprecated_bg4};
  }
  &:hover {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.v2.textTertiary};
  }
  &:active {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.v2.deprecated_bg4};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`;

export const ButtonEmpty = styled(BaseButton)`
  background-color: transparent;
  color: ${({ theme }) => theme.v2.accentAction};
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`;

export const ButtonText = styled(BaseButton)`
  padding: 0;
  width: fit-content;
  background: none;
  text-decoration: none;
  &:focus {
    text-decoration: underline;
  }
  &:hover {
    opacity: 0.9;
  }
  &:active {
    text-decoration: underline;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`;

const ButtonConfirmedStyle = styled(BaseButton)`
  background-color: ${({ theme }) => theme.v2.deprecated_bg3};
  color: ${({ theme }) => theme.v2.textPrimary};
  /* border: 1px solid ${({ theme }) => theme.v2.accentSuccess}; */

  &:disabled {
    opacity: 50%;
    background-color: ${({ theme }) => theme.v2.backgroundInteractive};
    color: ${({ theme }) => theme.v2.textSecondary};
    cursor: auto;
  }
`;

const ButtonErrorStyle = styled(BaseButton)`
  background-color: ${({ theme }) => theme.v2.accentFailure};
  border: 1px solid ${({ theme }) => theme.v2.accentFailure};

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.v2.accentFailure)};
    background-color: ${({ theme }) => darken(0.05, theme.v2.accentFailure)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.v2.accentFailure)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.v2.accentFailure)};
    background-color: ${({ theme }) => darken(0.1, theme.v2.accentFailure)};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
    box-shadow: none;
    background-color: ${({ theme }) => theme.v2.accentFailure};
    border: 1px solid ${({ theme }) => theme.v2.accentFailure};
  }
`;

export function ButtonConfirmed({
  altDisabledStyle,
  confirmed,
  ...rest
}: { confirmed?: boolean; altDisabledStyle?: boolean } & ButtonProps) {
  return confirmed ? (
    <ButtonConfirmedStyle {...rest} />
  ) : (
    <ButtonPrimary {...rest} altDisabledStyle={altDisabledStyle} />
  );
}

export function ButtonError({
  error,
  ...rest
}: { error?: boolean } & ButtonProps) {
  return error ? <ButtonErrorStyle {...rest} /> : <ButtonPrimary {...rest} />;
}

export function ButtonDropdown({
  children,
  disabled = false,
  ...rest
}: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonPrimary {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ alignItems: 'center', display: 'flex' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonPrimary>
  );
}

export function ButtonDropdownLight({
  children,
  disabled = false,
  ...rest
}: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonOutlined {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ alignItems: 'center', display: 'flex' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonOutlined>
  );
}

const ActiveOutlined = styled(ButtonOutlined)`
  border: 1px solid;
  border-color: ${({ theme }) => theme.v2.accentAction};
`;

const Circle = styled.div`
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.v2.accentAction};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CheckboxWrapper = styled.div`
  width: 20px;
  padding: 0 10px;
  position: absolute;
  top: 11px;
  right: 15px;
`;

const ResponsiveCheck = styled(Check as any)`
  size: 13px;
`;

export function ButtonRadioChecked({
  active = false,
  children,
  ...rest
}: { active?: boolean } & ButtonProps) {
  const theme = useTheme();

  return active ? (
    <ActiveOutlined {...rest} padding="12px 8px" $borderRadius="12px">
      <RowBetween>
        {children}
        <CheckboxWrapper>
          <Circle>
            <ResponsiveCheck size={13} stroke={theme.v2.white} />
          </Circle>
        </CheckboxWrapper>
      </RowBetween>
    </ActiveOutlined>
  ) : (
    <ButtonOutlined $borderRadius="12px" padding="12px 8px" {...rest}>
      <RowBetween>{children}</RowBetween>
    </ButtonOutlined>
  );
}

export enum ButtonSize {
  small,
  medium,
  large,
}
export enum ButtonEmphasis {
  high,
  promotional,
  highSoft,
  medium,
  low,
  warning,
  destructive,
}
interface BaseThemeButtonProps {
  size: ButtonSize;
  emphasis: ButtonEmphasis;
}

function pickThemeButtonBackgroundColor({
  emphasis,
  theme,
}: {
  theme: DefaultTheme;
  emphasis: ButtonEmphasis;
}) {
  switch (emphasis) {
    case ButtonEmphasis.high: {
      return theme.v2.accentAction;
    }
    case ButtonEmphasis.promotional: {
      return theme.v2.accentActionSoft;
    }
    case ButtonEmphasis.highSoft: {
      return theme.v2.accentActionSoft;
    }
    case ButtonEmphasis.low: {
      return 'transparent';
    }
    case ButtonEmphasis.warning: {
      return theme.v2.accentWarningSoft;
    }
    case ButtonEmphasis.destructive: {
      return theme.v2.accentCritical;
    }
    default: {
      return theme.v2.backgroundInteractive;
    }
  }
}
function pickThemeButtonFontSize({ size }: { size: ButtonSize }) {
  switch (size) {
    case ButtonSize.large: {
      return '20px';
    }
    case ButtonSize.medium: {
      return '16px';
    }
    case ButtonSize.small: {
      return '14px';
    }
    default: {
      return '16px';
    }
  }
}
function pickThemeButtonLineHeight({ size }: { size: ButtonSize }) {
  switch (size) {
    case ButtonSize.large: {
      return '24px';
    }
    case ButtonSize.medium: {
      return '20px';
    }
    case ButtonSize.small: {
      return '16px';
    }
    default: {
      return '20px';
    }
  }
}
function pickThemeButtonPadding({ size }: { size: ButtonSize }) {
  switch (size) {
    case ButtonSize.large: {
      return '16px';
    }
    case ButtonSize.medium: {
      return '10px 12px';
    }
    case ButtonSize.small: {
      return '8px';
    }
    default: {
      return '10px 12px';
    }
  }
}
function pickThemeButtonTextColor({
  emphasis,
  theme,
}: {
  theme: DefaultTheme;
  emphasis: ButtonEmphasis;
}) {
  switch (emphasis) {
    case ButtonEmphasis.high:
    case ButtonEmphasis.promotional: {
      return theme.v2.accentAction;
    }
    case ButtonEmphasis.highSoft: {
      return theme.v2.accentAction;
    }
    case ButtonEmphasis.low: {
      return theme.v2.textSecondary;
    }
    case ButtonEmphasis.warning: {
      return theme.v2.accentWarning;
    }
    case ButtonEmphasis.destructive: {
      return theme.v2.accentTextDarkPrimary;
    }
    default: {
      return theme.v2.textPrimary;
    }
  }
}

const BaseThemeButton = styled.button<BaseThemeButtonProps>`
  align-items: center;
  background-color: ${pickThemeButtonBackgroundColor};
  border-radius: 16px;
  border: 0;
  color: ${pickThemeButtonTextColor};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: ${pickThemeButtonFontSize};
  font-weight: 600;
  gap: 12px;
  justify-content: center;
  line-height: ${pickThemeButtonLineHeight};
  padding: ${pickThemeButtonPadding};
  position: relative;
  transition: 150ms ease opacity;
  user-select: none;

  :active {
    ${ButtonOverlay} {
      background-color: ${({ theme }) => theme.v2.stateOverlayPressed};
    }
  }
  :focus {
    ${ButtonOverlay} {
      background-color: ${({ theme }) => theme.v2.stateOverlayPressed};
    }
  }
  :hover {
    ${ButtonOverlay} {
      background-color: ${({ theme }) => theme.v2.stateOverlayHover};
    }
  }
  :disabled {
    cursor: default;
    opacity: 0.6;
  }
  :disabled:active,
  :disabled:focus,
  :disabled:hover {
    ${ButtonOverlay} {
      background-color: transparent;
    }
  }
`;

interface ThemeButtonProps
  extends React.ComponentPropsWithoutRef<'button'>,
    BaseThemeButtonProps {}

export const ThemeButton = ({ children, ...rest }: ThemeButtonProps) => {
  return (
    <BaseThemeButton {...rest}>
      <ButtonOverlay />
      {children}
    </BaseThemeButton>
  );
};

export const ButtonLight = ({ children, ...rest }: BaseButtonProps) => {
  return (
    <BaseButtonLight {...rest}>
      <ButtonOverlay />
      {children}
    </BaseButtonLight>
  );
};
