import { SxProps } from '@mui/system';

export type Variant = 'primary' | 'secondary' | 'ghost' | 'round' | 'danger';
export type Size =
  | 'sm'
  | 'md'
  | 'lg'
  | 'small'
  | 'medium'
  | 'large'
  | 'rounded';

const PRIMARY_COLOR = 'rgba(16, 96, 240, 1)';
const PRIMARY_COLOR_HOVER = 'rgba(16, 96, 240, 0.5)';
const SECONDARY_COLOR = 'rgba(214, 218, 218, 1)';
const SECONDARY_COLOR_HOVER = 'rgba(214, 218, 218, 0.5)';
const DANGER_COLOR = 'rgba(212, 14, 14, 1)';
const DANGER_COLOR_HOVER = 'rgba(212, 14, 14, 0.5)';

const defaultText: SxProps = {
  color: SECONDARY_COLOR,
  fontSize: 16,
  fontWeight: 600,
  lineHeight: 20,
};

const defaultButtonProps = {
  ':disabled': {
    borderWidth: '2px',
    color: SECONDARY_COLOR_HOVER,
    opacity: 1,
  },
  ':hover': {
    ...defaultText,
    borderWidth: '2px',
    cursor: 'pointer',
    opacity: 1,
  },
  ':hover:disabled': {
    cursor: 'not-allowed',
  },
  alignItems: 'center',
  borderRadius: 50,
  borderStyle: 'solid',
  borderWidth: '2px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  overflow: 'hidden',
  transition: 'all 0.2s ease-in-out',
};

export const primary: SxProps = {
  ...defaultButtonProps,
  ...defaultText,
  ':disabled': {
    ...defaultButtonProps[':disabled'],
    background: 'transparent',
  },
  ':hover': {
    ...defaultButtonProps[':hover'],
    ...defaultText,
    background: PRIMARY_COLOR_HOVER,
    backgroundColor: PRIMARY_COLOR_HOVER,
    borderColor: PRIMARY_COLOR_HOVER,
  },
  background: PRIMARY_COLOR,
  backgroundColor: PRIMARY_COLOR,
  backgroundImage: 'none',
  borderColor: PRIMARY_COLOR,
};

export const secondary: SxProps = {
  ...defaultButtonProps,
  ...defaultText,
  ':disabled': {
    ...defaultButtonProps[':disabled'],
    background: 'transparent',
  },
  ':hover': {
    ...defaultText,
    ...defaultButtonProps[':hover'],
    background: SECONDARY_COLOR_HOVER,
    backgroundColor: SECONDARY_COLOR_HOVER,
    borderColor: SECONDARY_COLOR_HOVER,
  },
  background: 'transparent',
  backgroundColor: 'transparent',
  borderColor: SECONDARY_COLOR,
};

export const danger: SxProps = {
  ...defaultButtonProps,
  ...defaultText,
  ':disabled': {
    ...defaultButtonProps[':disabled'],
    background: 'transparent',
    color: DANGER_COLOR,
  },
  ':hover': {
    ...defaultText,
    ...defaultButtonProps[':hover'],
    background: DANGER_COLOR_HOVER,
    backgroundColor: DANGER_COLOR_HOVER,
    borderColor: DANGER_COLOR_HOVER,
  },
  background: DANGER_COLOR,
  backgroundColor: DANGER_COLOR,
  borderColor: DANGER_COLOR,
};

export const ghost: SxProps = {
  ...defaultButtonProps,
  ...defaultText,
  ':disabled': {
    ...defaultButtonProps[':disabled'],
    background: 'transparent',
  },
  ':hover': {
    ...defaultText,
    ...defaultButtonProps[':hover'],
    background: SECONDARY_COLOR_HOVER,
    backgroundColor: SECONDARY_COLOR_HOVER,
    borderColor: 'transparent',
  },
  background: 'transparent',
  backgroundColor: 'transparent',
  borderColor: 'transparent',
};

export const round: SxProps = {
  ...defaultButtonProps,
  borderRadius: 1000,
  gap: '10px',
  minWidth: 0,
  padding: '4px',
};

export const rounded: SxProps = {
  gap: '5px',
  padding: '2px',
};

export const small: SxProps = {
  fontSize: '13px',
  gap: '10px',
  height: '28px',
  padding: '6px 12px',
};

export const medium: SxProps = {
  gap: '10px',
  height: '36px',
  padding: '8px 16px',
};

export const variants = {
  danger,
  ghost,
  primary,
  round,
  secondary,
};

export const sizes = {
  lg: medium,
  md: medium,
  rounded,
  sm: small,
};
